import _ from "lodash";
import { __ } from "@wordpress/i18n";
import placeholderImage from '../material/placeholder.png';

//Icons
import { ReactComponent as FallbackIcon } from "../material/icons/fallback.svg";
import { ReactComponent as regional_services_restaurant } from '../material/icons/services/goland_restaurant_icon.svg';
import { ReactComponent as regional_services_health_club } from '../material/icons/services/goland_health_club_icon.svg';
import { ReactComponent as regional_services_hairdresser } from '../material/icons/services/goland_hairdresser_icon.svg';
import { ReactComponent as regional_services_grocery } from '../material/icons/services/goland_grocery_icon.svg';
import { ReactComponent as regional_services_kiosk } from '../material/icons/services/goland_kiosk_icon.svg';
import { ReactComponent as regional_services_laundry } from '../material/icons/services/goland_laundry_icon.svg';
import { ReactComponent as regional_services_car_wash } from '../material/icons/services/goland_car_wash_icon.svg';
import { ReactComponent as regional_services_library } from '../material/icons/services/goland_library_icon.svg';
import { ReactComponent as regional_services_postal_services } from '../material/icons/services/goland_postal_services_icon.svg';
import { ReactComponent as regional_services_cafe } from '../material/icons/services/goland_cafe_icon.svg';
//import { ReactComponent as regional_services_hotel } from '../material/icons/services/goland_hotel_icon.svg';
import { ReactComponent as regional_services_shopping_center } from '../material/icons/services/goland_shopping_center_icon.svg';
import { ReactComponent as regional_services_fair_center } from '../material/icons/services/goland_fair_center_icon.svg';
import { ReactComponent as regional_services_swimming_hall } from '../material/icons/services/goland_swimming_hall_icon.svg';
import { ReactComponent as regional_services_healthcare_services } from '../material/icons/services/goland_healthcare_services_icon.svg';
import { ReactComponent as regional_services_sport_center } from '../material/icons/services/goland_sport_center_icon.svg';
import { ReactComponent as regional_services_pub } from '../material/icons/services/goland_cafe_icon.svg';
//import { ReactComponent as regional_services_parking_facility } from '../material/icons/services/goland_parking_facility_icon.svg';

import { ReactComponent as services_reception } from '../material/icons/services/goland_reception_icon.svg';
import { ReactComponent as services_canteen } from '../material/icons/services/goland_canteen_icon.svg';
import { ReactComponent as services_cafe } from '../material/icons/services/goland_cafe_icon.svg';
import { ReactComponent as services_catering } from '../material/icons/services/goland_catering_icon.svg';
import { ReactComponent as services_leasable_meeting_rooms } from '../material/icons/services/goland_leasable_meeting_rooms_icon.svg';
import { ReactComponent as services_auditorium } from '../material/icons/services/goland_auditorium_icon.svg';
import { ReactComponent as services_sauna } from '../material/icons/services/goland_sauna_department_icon.svg';
import { ReactComponent as services_gym } from '../material/icons/services/goland_gym_icon.svg';
import { ReactComponent as services_video_conference } from '../material/icons/services/goland_video_conference_icon.svg';
import { ReactComponent as services_cleaning } from '../material/icons/services/goland_cleaning_services_icon.svg';
import { ReactComponent as services_facility_management } from '../material/icons/services/goland_facility_management_icon.svg';
import { ReactComponent as services_it_onsite_services } from '../material/icons/services/goland_it_onsite_service_icon.svg';
import { ReactComponent as services_leasable_furniture } from '../material/icons/services/goland_leasable_furniture_icon.svg';
import { ReactComponent as services_office_services } from '../material/icons/services/goland_office_services_icon.svg';
import { ReactComponent as services_hairdresser } from '../material/icons/services/goland_hairdresser_icon.svg';
import { ReactComponent as services_car_wash } from '../material/icons/services/goland_car_wash_icon.svg';
import { ReactComponent as services_laundry } from '../material/icons/services/goland_laundry_icon.svg';
import { ReactComponent as services_massage } from '../material/icons/services/goland_massage_icon.svg';
import { ReactComponent as services_dressing_rooms_shower } from '../material/icons/services/goland_dressingrooms_And_shower_icon.svg';
import { ReactComponent as services_bicycle_room } from '../material/icons/services/goland_bicycle_room_icon.svg';
//import { ReactComponent as services_vine_cellar } from '../material/icons/services/goland_vine_cellar_icon.svg';
import { ReactComponent as services_grocery_shop } from '../material/icons/services/goland_grocery_shop_icon.svg';
/**
 * Helper function to retrieve the readable text value of property information stored in the filters.
 * 
 * Usage example:
 * 
 * `findFilterText(goland.filters, property_main_types, property.main_type)`
 * @param {Object} filters The filters object
 * @param {String} propertykey The object key in filters from where we search with the `apivalue` param
 * @param {*} apivalue The key of the selected filter property we want to retrieve
 * @returns {String} The readable text value of the property
 */
export function findFilterText(filters, propertykey, apivalue) {

  const options = filters[propertykey] || {};
  let option = _.find(options, ['key', apivalue]);
  if (option) {
    return option.text;
  }
  return "";
}

/**
 * Find the image's version object by image type and size (optional)
 * 
 * If specified type or size is not found, the first image/size is returned
 * 
 * @param {Array} imagesArray Array of images from the API
 * @param {string} [selectedType=outside] The desired image type, default value "outside"
 * @param {String} [size=medium] The image size, default value "medium"
 * @param {string} [index=0] If there are multiple images with the same type, choose the one at the selected
 * index. Default is the first image
 * @returns {Object} The version object
 */
export function findImageByType(imagesArray, selectedType = "outside", size = "medium", placeholder = placeholderImage, index = "0") {

  // Determine placeholder
  let placeholderObject = {
    'name': 'placeholder',
    'url': placeholder,
    'alt': 'Placeholder image'
  }


  // Add placeholder, if no images exist
  if (!imagesArray || imagesArray.length == 0) {
    let versionObject = placeholderObject;
    return versionObject;
  }

  // Find the image object by type
  let imageObject = imagesArray.filter(image => image.type === selectedType);

  // If the selected type wasn't found, get the first image object
  //if (!imageObject) imageObject = imagesArray[0]; // ENABLE THIS, IF YOU'D RATHER HAVE AN IMAGE, THAN A PLACEHOLDER
  if (!imageObject) {
    return imageObject = placeholderObject;
  }

  let versionObject;

  // Get the image at the given index, if not found, use the first image
  if (imageObject[index] && imageObject[index].versions) {
    versionObject = imageObject[index].versions.find(version => version.name === size);
    if (versionObject && versionObject.length === 0) versionObject = imageObject[index].versions[0];
  } else if (imageObject[0] && imageObject[0].versions) {
    versionObject = imageObject[0].versions.find(version => version.name === size);
    if (versionObject.length === 0) versionObject = imageObject.versions[0];
  }


  // If the selected size wasn't found, get the first size
  if (!versionObject) versionObject = placeholderObject;
  // Add the alt text to object
  versionObject.alt = placeholderObject.alt;

  return versionObject;
}



/**
 * Parse block's attributes and convert strigified booleans back to true booleans.
 * 
 * Useful in `frontend.js` files where the attributes are stringified
 * 
 * @param { object } attributes The attributes object
 * @returns { object } Attributes object with parsed booleans
 */
export function parseAttributeBooleans(attributes) {
  Object.keys(attributes).forEach(key => {
    if (attributes[key] == "true") {
      attributes[key] = true;
    }
    if (attributes[key] == "false") {
      attributes[key] = false;
    }
  })
  return attributes;
}

/**
   * Basic function to get data from the filters.json
   *
   * @param {object} source needed filter in filters.json (Example: goland.filters.main_type)
   * @param {string} originalData the key. (Example: units.data.dataSource)
   * @returns {string} string - The result.
   */
export function getValues(source, originalData) {
  let result = "";
  source.filter((filterObject) => {
    if (originalData == filterObject.key) result = filterObject.text;
  });
  return result;
}

/**
 * Get the types and return an object of arrays (than can be joined later)
 * -- Specification requirement -- 
 * If type_specifications includes "storage":
 * - Set main_type = "storage"
 * 
 * @param {object} source - For example ad
 * @param {boolean} showAltType - Is alternative_type shown/added to list of types? (optional) 
 * @param {boolean} showTypeSpecs - Is type_specifications shown/added to list of types? (optional)
 * @returns {object} types - Includes types.main (string), and types.other (array)
 */
export function getAdTypes(ad, showAltType = true, showTypeSpecs = true) {
  let types = {};
  types.other = [];

  // Main type
  if (ad?.main_type) {
    // Fetch values
    types.main = goland.filters.premises_main_types.find(
      (type) => type.key === ad.main_type
    ).text;
  }

  // Alternative type
  if (ad?.alternative_type && showAltType == true) {
    // Put into array
    types.other = [goland.filters.premises_alternative_types.find(
      (type) => type.key === ad.alternative_type
    ).text];
  }

  // Check type specifications
  if (ad?.type_specifications.length > 0 && showTypeSpecs == true) {
    // If type_specification includes storage, re-set main type as "Storage"
    if (ad.type_specifications.includes('storage')) {
      types.main = __('Storage', 'goland-features');
    }

    // Add each type specification
    ad.type_specifications.forEach((typeSpecObj) => {
      if (ad.main_type === 'warehouse' && typeSpecObj === 'storage') {
        return
      }
      types.other.push(
        goland.filters.type_specifications.find((typeSpec) =>
          typeSpec.key === typeSpecObj
        ).text
      )
    });
  }

  return types;
}

/** 
 * Get all areas (total, actual, sub_areas)
 * 
 * @param {object} source Such as "ad", or "property"
 * @param {separator} separator (Optional) The separator of the sub areas
 * @returns {object} areas - Includes areas.total (string), areas.actual (string), area.sub (string)
 */
export function getAdAreas(ad, separator = "/") {
  let areas = {};
  areas.sub = []; // Instantiate as array, stringify later

  // Total area
  if (ad?.total_area) {
    areas.total = numberToLocaleString(ad.total_area) + ' ' + goland.units.measurement.area_text;
    }

  // Actual area
  if (ad?.actual_area) {
    areas.actual = numberToLocaleString(ad.actual_area) + ' ' + goland.units.measurement.area_text;
  }

  // Sub areas
  if (ad?.sub_areas && ad.sub_areas.length > 0) {
    ad.sub_areas.forEach((sub_area) => areas.sub.push(numberToLocaleString(sub_area)));
    areas.sub = "(" + areas.sub.join(separator) + ' ' + goland.units.measurement.area_text + ")";
  } else {
    areas.sub = ""; // Stringify
  }

  return areas;
}

/**
 * Fetches, parses, and returns the dates in appropriate form
 * 
 * @param {object} ad - This is the source, such as "ad" or "paramter"
 * @returns {string} availability
 */
export function getAdAvailability(ad) {
  // Availability
  let availability;
  // Availability_date exists?
  if (ad.availability_date) {
    // Prefix
    availability = __('Available', 'goland-features') + ' ';

    // Format date
    const date = new Date(ad.availability_date);
    const dateString = date.toLocaleDateString();

    // Prefix + formatted date
    availability = availability + dateString;

  } else { // Availability_date doesn't exist

    // Availability exists?
    if (ad.availability) {
      availability = goland.filters.availabilities.find(
        (obj) => obj.key === ad.availability
      );
      availability = availability.text;
    } else { // Availability doesn't exist
      availability = '';
    }
  }

  return availability;
}

/**
 * Returns the ad's price string
 * 
 * @param {object} ad - Source
 * @param {string} separator - The separator (optional)
 * @returns {string} prices
 */
export function getAdPrices(ad, separator = " | ") {
  let price;
  let leaseSuffix = " " + goland.units.currency.sign + __("/mo", 'goland-features')
  let saleSuffix = " " + goland.units.currency.sign;

  // Lease
  if ('for_rent' == ad.sales_method) {
    if (!ad.rent_total) return price = __('Ask for Rent', 'goland-features');
    return price = ad.rent_total + leaseSuffix;
  }

  // Sale
  if ('for_sale' == ad.sales_method) {
    if (!ad.selling_price) return price = __('Ask for Price', 'goland-features');
    return price = ad.selling_price + saleSuffix;
  }

  // Lease & Sale
  if ('for_sale_rent' == ad.sales_method) {

    // Lease
    if (!ad.rent_total) {
      price = __('Ask for Rent', 'goland-features');
    } else {
      price = ad.rent_total + leaseSuffix;
    }
    // Sale
    if (!ad.selling_price) {
      price = price + separator + __('Ask for Price', 'goland-features');
    } else {
      price = price + separator + ad.selling_price + saleSuffix;
    }

  }

  return price;
}

/**
 * Get an SVG icon for the service
 * @param {string} serviceType The service type from service.type
 * 
 * @returns {ReactComponent} The Icon as an inline SVG
 */
export function getServiceIcon(serviceType) {
  const icons = {
		regional_services_restaurant,
		regional_services_health_club,
		regional_services_hairdresser,
		regional_services_grocery,
		regional_services_kiosk,
		regional_services_laundry,
		regional_services_car_wash,
		regional_services_library,
		regional_services_postal_services,
		regional_services_cafe,
		//regional_services_hotel,
		regional_services_shopping_center,
		regional_services_fair_center,
		regional_services_swimming_hall,
		regional_services_healthcare_services,
		regional_services_sport_center,
		regional_services_pub,
		//regional_services_parking_facility,
    services_reception,
    services_canteen,
    services_cafe,
    services_catering,
    services_leasable_meeting_rooms,
    services_auditorium,
    services_sauna,
    services_gym,
    services_video_conference,
    services_cleaning,
    services_facility_management,
    services_it_onsite_services,
    services_leasable_furniture,
    services_office_services,
    services_hairdresser,
    services_car_wash,
    services_laundry,
    services_massage,
    services_dressing_rooms_shower,
    services_bicycle_room,
    services_grocery_shop,
	}
  return icons[serviceType] || FallbackIcon;
}

/**
 * Convert number to locale string
 * @param {*} number 
 * @returns {string} The number as a locale string
 */
export function numberToLocaleString(number) {
  if (typeof number !== 'number') {
    number = parseFloat(number);
  }
  return new Intl.NumberFormat('fi-FI', {
    useGrouping: false,
    minimumFractionDigits: 0,
    maximumFractionDigits: 20 // Adjust as needed,
  }).format(number);
}

/**
 * Helper function to replace nordic characters for URL
 * @param {string} str - The string to replace characters in 
 * @returns {string} The string with replaced characters
 */
export function replaceNordicCharacters(str) {
  if (!str) {
    return '';
  }
  return str.replace(/ä/g, 'a').replace(/ö/g, 'o');
}